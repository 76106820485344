<template>
  <div>
    <div class="flex">
      <side-bar :show-app-links="false" :show-settings="false" />
      <div class="w-full grow overflow-auto pb-20 lg:ml-[270px]">
        <Modals />
        <div class="mx-auto max-w-[120rem]">
          <nav-bar
            :show-actions="hasOnboardingAcknowledged"
            class="px-4 md:px-0"
          />
          <slot />
        </div>
      </div>
    </div>
    <AuthAutoLogout />
  </div>
</template>

<script setup lang="ts">
import SideBar from '@/components/shared/side-bar/side-bar.vue'
import Modals from '~/components/shared/modals/the-modals.vue'
import NavBar from '@/components/shared/nav-bar.vue'

const featureFlagStore = useFeatureFlagStore()
const { hasOnboardingAcknowledged } = storeToRefs(featureFlagStore)
</script>
